export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは5月より%d点上がりました。ジメジメした梅雨の時期も上手く乗り切れていますね。<br>\n7月のテーマは、<b>❝熱中症予防と対策❞</b>​   です。熱中症予防のためには、<u>のどの渇きを感じていなくても</u>、「こまめに水分補給をすること」と、日陰や日傘を活用して、「暑さを避けること」を心がけてください。その日の体調などにより、暑さの感じ方は個人差があります。身体の声に耳を傾けるようにしましょう。\n\n",
      "down": "生活習慣スコアは5月より%d点下がりました。食事・運動など、どの項目に変化がありましたか？<br>\n7月のテーマは、<b>❝熱中症予防と対策❞</b>​ ​   です。熱中症予防のためには、<u>のどの渇きを感じていなくても</u>、「こまめに水分補給をすること」と、日陰や日傘を活用して、「暑さを避けること」を心がけてください。その日の体調などにより、暑さの感じ方は個人差があります。身体の声に耳を傾けるようにしましょう。\n\n",
      "equal100": "生活習慣スコアは5月に引き続き%満点%です。大変素晴らしいです！<br>\n7月のテーマは、<b>❝熱中症予防と対策❞</b>​ ​   です。熱中症予防のためには、<u>のどの渇きを感じていなくても</u>、「こまめに水分補給をすること」と、日陰や日傘を活用して、「暑さを避けること」を心がけてください。その日の体調などにより、暑さの感じ方は個人差があります。身体の声に耳を傾けるようにしましょう。\n\n",
      "equal": "生活習慣スコアは5月と同じ点数です。<br>\n7月のテーマは、<b>❝熱中症予防と対策❞</b>​ ​   です。熱中症予防のためには、<u>のどの渇きを感じていなくても</u>、「こまめに水分補給をすること」と、日陰や日傘を活用して、「暑さを避けること」を心がけてください。その日の体調などにより、暑さの感じ方は個人差があります。身体の声に耳を傾けるようにしましょう。\n\n"
    },
    "steps": {
      "up": "歩数スコアは5月より%d点上がりました。<br>\n雨の多い時期も歩数を増やせていますね。素晴らしいです！\r\n良い習慣は上手く継続していきましょう。\n\n",
      "down": "歩数スコアは5月より%d点下がりました<br>\n階段を使うようにする、スーパーや駅に行くときに少しだけ遠回りをするなど、ちょっと意識するだけでも歩数を増やすことができますよ。\n\n",
      "equal": "歩数スコアは5月と同じ点数です。<br>\n階段を使うようにする、スーパーや駅に行くときに少しだけ遠回りをするなど、ちょっと意識するだけでも歩数を増やすことができますよ。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは5月より%d点上がりました。<br>\n身体を動かす機会が多かったようですね！爽快感や達成感を感じることはありますか？\n\n",
      "down": "運動のスコアは5月より%d点下がりました。<br>\n今より10分間多く体を動かすことを1年間続けると、<b>1.5～2㎏の減量効果</b>が期待できます。10分間の運動動画は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">こちら</a>！\n\n",
      "equal": "運動のスコアは5月と同じ点数です。<br>\n今より10分間多く体を動かすことを1年間続けると、<b>1.5～2㎏の減量効果</b>が期待できます。10分間の運動動画は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">こちら</a>！\n\n"
    },
    "meal": {
      "up": "食事のスコアは5月より%d点上がりました。<br>\n食事の時、一口<b>30回以上噛む</b>ことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。\n\n",
      "down": "食事のスコアは5月より%d点下がりました。<br>\n食べ過ぎや、寝る前に食事をした日が多かったようです。体重計測をして、身体の変化にいち早く気が付けるようにしましょう。\n\n",
      "equal": "食事のスコアは5月と同じ点数です。<br>\n食事の時、一口<b>30回以上噛む</b>ことが理想です。あなたは何回噛んでいますか？少ない人は、まずは今より5回増やしてみましょう。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは5月より%d点上がりました。<br>\nそろそろビールが美味しくなる季節ですね。ビールは1日当たり中ビン1本が適量と言われています。他の種類のお酒の適量は<a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">コラム</a>で確認！\n\n",
      "down": "飲酒のスコアは5月より%d点下がりました。<br>\nそろそろビールが美味しくなる季節ですね。ビールは1日当たり中ビン1本が適量と言われています。他の種類のお酒の適量は<a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">コラム</a>で確認！\n\n",
      "equal": "飲酒のスコアは5月と同じ点数です。<br>\nそろそろビールが美味しくなる季節ですね。ビールは1日当たり中ビン1本が適量と言われています。他の種類のお酒の適量は<a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">コラム</a>で確認！\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは5月より%d点下がりました。<br>\n日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。\n\n",
      "down": "睡眠のスコアは5月より%d点下がりました。<br>\n日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。\n\n",
      "equal": "睡眠のスコアは5月と同じ点数です。<br>\n日中の眠気対策には、脳や体を覚醒させてくれるストレッチがおすすめ！体をひねったり、伸びをしてみましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは5月より%d点上がりました。<br>\n気分が良い日が増えています。良い気分でいると、仕事が捗ったり、気持ちの切替が上手くなったり、メリットがいろいろあります。\n\n",
      "down": "ストレスのスコアは5月より%d点下がりました。<br>\n気分が悪い日が増えていますね。誰かに話したり、ゆっくり休んだり、できそうなストレスケアを試してみましょう。\n\n",
      "equal": "ストレスのスコアは5月と同じ点数です。<br>\n”笑う”ことで、心身ともにリラックス効果が期待できます。口角を上げて、声を出して笑いましょう！\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your Lifestyle Score went up by %d points since May. Getting along through the gloomy rainy season well, aren't we?<br>\nThe theme for July is <b>\"Heat Stroke Prevention and Countermeasures.\"</b> Make sure to \"beat the heat\" with some shade or a parasol and \"stay hydrated,\" <u>even if you don't feel thirsty</u>, to prevent heat stroke. How you feel the heat varies from person to person, depending on things like your physical condition on that day. Try to listen to your body.\n\n",
      "down": "Your Lifestyle Score went down by %d points since May. Which items, such as diet and exercise, have changed?<br>\nThe theme for July is <b>\"Heat Stroke Prevention and Countermeasures.\"</b> Make sure to \"beat the heat\" with some shade or a parasol and \"stay hydrated,\" <u>even if you don't feel thirsty</u>, to prevent heat stroke. How you feel the heat varies from person to person, depending on things like your physical condition on that day. Try to listen to your body.\n\n",
      "equal100": "Your Lifestyle score is perfect, as it was in May. Excellent job!<br>\nThe theme for July is <b>\"Heat Stroke Prevention and Countermeasures.\"</b> Make sure to \"beat the heat\" with some shade or a parasol and \"stay hydrated,\" <u>even if you don't feel thirsty</u>, to prevent heat stroke. How you feel the heat varies from person to person, depending on things like your physical condition on that day. Try to listen to your body.\n\n",
      "equal": "Your Lifestyle score is the same as it was in May.<br>\nThe theme for July is <b>\"Heat Stroke Prevention and Countermeasures.\"</b> Make sure to \"beat the heat\" with some shade or a parasol and \"stay hydrated,\" <u>even if you don't feel thirsty</u>, to prevent heat stroke. How you feel the heat varies from person to person, depending on things like your physical condition on that day. Try to listen to your body.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d pts since May.<br>\nYou're getting more steps even during the rainy season. That's fantastic! \r\nKeep up the good habits.\n\n",
      "down": "Your steps score has gone down %d pts since May. <br>\nYou can increase your steps with a bit of conscious effort. For example, use the stairs, or take a short detour when you go to the supermarket or station.\n\n",
      "equal": "Your steps score is the same as May. <br>\nYou can increase your steps with a bit of conscious effort. For example, use the stairs, or take a short detour when you go to the supermarket or station.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d pts since May.<br>\nIt seems like you've had a lot of opportunities for physical exercise! Do you feel a sense of exhilaration or accomplishment?\n\n",
      "down": "Your exercise score has gone down %d pts since May.<br>\nIf you move your body for 10 more minutes every day for a year, you can lose <b>1.5 to 2 kg</b>. Watch the 10-minute exercise video <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">here</a>!\n\n",
      "equal": "Your exercise score is the same as May.<br>\nIf you move your body for 10 more minutes every day for a year, you can lose <b>1.5 to 2 kg</b>. Watch the 10-minute exercise video <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">here</a>!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d pts since May.<br>\nIdeally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.\n\n",
      "down": "Your food score has gone down %d pts since May.<br>\nIt seems like there were several days where you overate or ate before going to bed. Weigh yourself and be the first to notice any changes in your body.\n\n",
      "equal": "Your food score is the same as May.<br>\nIdeally, you <b>should chew at least 30 times per bite</b> when you eat. How many times do you chew? If you don't chew enough, start by chewing 5 more times per bite.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d pts since May.<br>\nIt's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. Check the <a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">column</a> for other types of alcohol!\n\n",
      "down": "Your alcohol score has gone down %d pts since May.<br>\nIt's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. Check the <a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">column</a> for other types of alcohol!\n\n",
      "equal": "Your alcohol score is the same as May.<br>\nIt's almost the season when beer tastes good. They say that one medium-sized bottle of beer per day is just enough. Check the <a href=\"https://go.andwell.jp/library/ab7cd783-d385-11ed-b85c-06528b823331\">column</a> for other types of alcohol!\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d pts since May.<br>\nGetting a good night's sleep can improve your abilities and concentration at work. Keep it up!\n\n",
      "down": "Your sleep score has gone down %d pts since May.<br>\nFor drowsiness during the day, wake up your mind and body by stretching! Twist and stretch your body.\n\n",
      "equal": "Your sleep score is the same as May.<br>\nFor drowsiness during the day, wake up your mind and body by stretching! Twist and stretch your body.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d pts since May.<br>\nYou've been having more days where you're feeling good. There's a lot that a good mood can help with, like getting work done and keeping your spirits high.\n\n",
      "down": "Your stress score has gone down %d pts since May.<br>\nYou've been having more days where you don't feel well. Talk to someone, get some rest, and try to do what you can to relieve some of that stress.\n\n",
      "equal": "Your stress score is the same as May.<br>\nLaughing can help relax both the body and mind. Open your mouth, raise your voice, and laugh!\n\n"
    }
  }
}